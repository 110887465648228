<template>
  <v-container class="pa-0">
    <v-row class="no-gutters elevation-0">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="custom-chat-height-wrapper">
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="chat-header">
              <label>
                <span>Car History Report</span>
                <small>Vin No: {{ carHistoryReport.vin_number }}</small>
              </label>
              <v-spacer></v-spacer>
              <v-btn
                medium
                light
                min-height="48"
                class="green darken-3 white--text"
                elevation="0"
                @click="expandToggle"
              >
                <v-icon>mdi-email</v-icon>
              </v-btn>
              <v-btn
                :href="carHistoryReport.pdf_path"
                target="_blank"
                medium
                light
                min-height="48"
                class="green darken-3 white--text ml-4"
                elevation="0"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </v-card-title>

            <v-card
              v-if="isExpanded"
              class="pa-4 form-custom d-flex align-center"
              flat
            >
              <v-text-field
                hide-details="auto"
                v-model="email"
                class="mr-3"
                height="50"
                elevation="0"
                :error-messages="emailError"
                @blur="$v.email.$touch()"
                :loading="loading"
                dense
                :label="$t('vinSearch.enterEmail')"
                solo
                outlined
              ></v-text-field>
              <v-btn
                height="50"
                medium
                @click="sendHistoryReport"
                dense
                class="green darken-4 green-gradient white--text"
                elevation="0"
              >
                {{ $t("vinSearch.send") }}
              </v-btn>
            </v-card>

            <v-card-text
              class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"
            >
              <template class="pa-4">
                <vue-pdf-app :pdf="carHistoryReport.pdf_path"></vue-pdf-app>
              </template>
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import { mapGetters, mapActions } from "vuex";
import { email, required } from "vuelidate/lib/validators";

export default {
  components: {
    VuePdfApp,
  },
  data() {
    return {
      loading: false,
      email: null,
      isExpanded: false,
      errors: {
        email: null,
      },
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  computed: {
    ...mapGetters({
      carHistoryReport: "carHistory/getCarHistoryReport",
    }),
    emailError() {
      let error = null;
      if (this.$v.email.$dirty && !this.$v.email.required) {
        error = this.$t("requiredValidation", {
          field: this.$t("carHistoryReport.email"),
        });
      }
      if (this.$v.email.$dirty && !this.$v.email.email) {
        error = this.$t("carHistoryReport.emailIsInvalid", {
          field: this.$t("carHistoryReport.email"),
        });
      }
      if (this.errors && this.errors.email) {
        error = this.errors.email;
      }
      return error;
    },
  },

  methods: {
    ...mapActions({
      sendHistoryReportMail: "carHistoryReport/sendHistoryReportMail",
    }),

    expandToggle() {
      if (this.isExpanded) {
        this.isExpanded = false;
        this.email = null;
        this.apiErrorMessage = null;
      } else {
        this.isExpanded = true;
      }
    },

    async sendHistoryReport() {
      this.loading = true;
      this.$v.$touch();

      try {
        await this.sendHistoryReportMail({
          vehicle_history_id: this.carHistoryReport.id,
          email: this.email,
        });
        this.expandToggle();
      } catch ({ message }) {
        this.errors.email = message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
